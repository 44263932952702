import { mapGetters, mapState } from 'vuex';
import mixinTheme from '@/components/mixins/mixinTheme';

export default {
  name: 'TemplateTopNav',
  props: {
    msg: String,
  },
  mixins: [mixinTheme],
  computed: {
    ...mapState({
      isEINAVBUAgency: (state) => state.agencyUsers.isEINAVBUAgency,
    }),
    ...mapGetters({
      lang: 'GET_LANG',
      homeLink: 'GET_HOME_LINK',
      device: 'GET_DEVICE',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerId: 'GET_MARKETER_ID',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      currentPage: 'GET_CURRENT_PAGE',
      isMarketerSite: 'GET_IS_MARKETER_SITE',
      enableSearchAgentState: 'GET_ENABLE_SEARCH_AGENT_STATE',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      isFCINTSPAgency: 'GET_IS_FCINTSP_AGENCY',
      isTargetAgency: 'GET_IS_TARGET_AGENCY',
      isByteAgency: 'GET_IS_BYTE_AGENCY',
    }),
    domesticLink() {
      return process.env.VUE_APP_DOMESTIC_DOMAIN;
    },
    showPricePlan() {
      return this.isMarketerSite && this.marketerAgencyContent?.forPricePlanOnly && !this.enableSearchAgentState && this.currentPage !== 'price-plan';
    },
    pricePlanLink() {
      return `/price-plan${this.homeLink}`;
    },
    logoURL() {
      return this.homeLink;
    },
    marketerLogo() {
      const { marketerAgencyContent, isFCINTSPAgency } = this;
      return !isFCINTSPAgency ?? (marketerAgencyContent?.logoUrls?.[0] ?? '');
    },
    isDefaultLogo() {
      const { marketerAgencyContent } = this;
      return ['GULLIV', 'FCDIRECT', 'TRAVLIS'].includes(marketerAgencyContent?.odyAgentCode);
    },
    srcLogo() {
      const { marketerAgencyContent } = this;
      const logo = marketerAgencyContent?.logoUrls?.[0] || this.whiteLabel.logoUrls[0];
      return logo;
    },
    isRegularSite() {
      return this.marketerId === '' && this.marketerAgencyContent === null;
    },
    // showDomesticLink() {
    //   let showDomestic = true;
    //   const marketerSite = this.marketerId !== '' || !this.whiteLabel?.forMainSite;
    //   if (marketerSite && this.whiteLabel?.showDomesticTourismLink) showDomestic = false;
    //   // const mainSite = this.whiteLabel?.forMainSite && !marketerSite;
    //   const agencySite = this.marketerId === '' && !this.whiteLabel?.forMainSite;
    //   if (agencySite && !this.marketerAgencyContent?.showDomesticTourismLink) showDomestic = false;
    //   return showDomestic;
    // },
    fellowLink() {
      let url = '';
      if (this.marketerId !== '') {
        if (this.marketerAgencyContent?.marketerSpecificContents?.fellowSiteUrl) {
          if (this.marketerAgencyContent?.marketerSpecificContents?.fellowSiteUrl.includes('https://')) {
            url = this.marketerAgencyContent?.marketerSpecificContents?.fellowSiteUrl;
          } else {
            url = `https://${this.marketerAgencyContent?.marketerSpecificContents?.fellowSiteUrl}`;
          }
        }
      } else {
        url = process.env.VUE_APP_DOMESTIC_DOMAIN;
      }
      return url;
    },
    showFellowLink() {
      let showPack = true;
      if (this.isMarketerSite) {
        showPack = this.marketerAgencyContent?.showDomesticTourismLink && this.fellowLink !== '';
      }
      return showPack;
    },
  },
  data() {
    return {
    };
  },
};
